import React, { FC, useRef, useState } from 'react'
import { ISbRichtext, SbBlokData, storyblokEditable } from '@storyblok/react'
import UtilButtonV2 from '@/v2/storyblok/utils/UtilButtonV2'
import BaseImageV2 from '@/v2/base/BaseImageV2'
import BaseRichText from '@/v2/base/BaseRichText'
import UtilIconTextButtonV2 from '../utils/UtilIconTextButtonV2'

type Props = {
  blok: {
    title: string
    text: ISbRichtext | string
    kicker: string
    text_hidden_cta: string
    text_hidden: ISbRichtext | string
    button: ISbUtilButtonV2[]
    layout: string
    image: ISbAsset
    image_is_hidden_mobile: boolean
  }
}

const ItemImageCardV2: FC<Props> = ({ blok }: Props) => {
  const [isActive, setIsActive] = useState(false)
  const hiddenText = useRef<HTMLDivElement>(null)

  return (
    <div
      {...storyblokEditable(blok)}
      className={`grid grid-cols-1 overflow-hidden rounded-5xl border border-v2-foreground-static-tertiary bg-white lg:grid-cols-2`}
    >
      <div className="whitespace-pre-line p-7 lg:p-20">
        <div className="">
          <span className="heading-6 mb-2 block text-v2-text-primary">{blok.kicker}</span>
          <h2 className="heading-3 mb-6 font-bitter text-v2-text-primary">{blok.title}</h2>
          {typeof blok.text === 'string' ? (
            <p className="body-2 mb-6 whitespace-pre-line text-v2-text-secondary">{blok.text}</p>
          ) : (
            <BaseRichText
              value={blok.text}
              classNames={{
                container: 'mb-6 body-2 text-v2-text-secondary prose prose-p:whitespace-pre-line',
              }}
            />
          )}

          {/* Hidden text */}
          <div
            ref={hiddenText}
            className={`overflow-hidden transition-all duration-300 ease-in-out ${isActive ? 'opacity-100' : 'opacity-0'}`}
            style={{ maxHeight: isActive ? `${hiddenText.current?.scrollHeight}px` : '0' }}
          >
            {typeof blok.text_hidden === 'string' ? (
              <p className="body-2 mb-6 text-v2-text-secondary">{blok.text_hidden}</p>
            ) : (
              <BaseRichText
                value={blok.text_hidden}
                classNames={{
                  container: 'mb-6 body-2 text-v2-text-secondary prose prose-p:whitespace-pre-line',
                }}
              />
            )}
          </div>

          {/* More button */}
          {!!blok.text_hidden && (
            <UtilIconTextButtonV2
              isIconRotated={isActive}
              text={blok.text_hidden_cta}
              icon="plus"
              onClick={() => setIsActive(!isActive)}
            />
          )}
        </div>

        <div className="mt-14 w-full md:w-fit">
          {blok.button.map((button: SbBlokData & ISbUtilButtonV2, index: number) => (
            <UtilButtonV2 key={index} blok={button} />
          ))}
        </div>
      </div>
      <div
        className={`h-full min-h-80 w-full overflow-hidden bg-cover bg-top bg-no-repeat [&_img]:h-full ${
          blok.layout === 'image-left' ? 'order-first' : 'order-first lg:order-last'
        }
      ${blok.image_is_hidden_mobile ? 'hidden sm:block' : ''}
      `}
      >
        <BaseImageV2
          focus={blok.image.focus}
          src={blok.image.filename}
          objectCover
          alt={blok.image.alt}
          breakPointsWidthMap={{ 0: 623 }}
        />
      </div>
    </div>
  )
}

export default ItemImageCardV2
