import React from 'react'
import { SbBlokData, storyblokEditable } from '@storyblok/react'
import ItemImageCardV2 from '@/v2/storyblok/items/ItemImageCardV2'

type Props = {
  blok: SbBlokData & ISbSectionImageCardsV2
}

const SectionImageCardsV2 = ({ blok }: Props) => {
  const background = `bg-v2-background-${blok.background || 'white'}`
  return (
    <div className="relative py-16 lg:py-24" {...storyblokEditable(blok)}>
      <div className="container-v2 relative z-10 mx-auto grid gap-16 lg:gap-24">
        {Array.isArray(blok.items) && blok.items.map((item, index) => <ItemImageCardV2 key={index} blok={item} />)}
      </div>
      {/* Background */}
      <div className="absolute top-0 h-full w-full py-64 ">
        <div className={`z-0 h-full w-full ${background}`}></div>
      </div>
    </div>
  )
}

export default SectionImageCardsV2
